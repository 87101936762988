<template>
  <div class="">
    <el-drawer
      v-model="addDrawer"
      :before-close="handleClose"
      :close-on-click-modal="false"
      size="40%"
    >
      <template #header>
        <div class="drawerHead">
          <div>
            {{ title }}
          </div>
          <div>
            <el-button
              type="primary"
              @click="saveCule"
              size="small"
              style="margin-right: 5px"
              >保存</el-button
            >
          </div>
        </div>
      </template>
      <template #default>
        <el-form :model="taskForm" :rules="rules" ref="taskForm" label-width="105px" :inline="true">
          <el-form-item label="客户名称:" prop="customerName">
            <el-input v-model="taskForm.customerName" size="small" class="w500" readonly>
              <template #append >
                <el-icon @click="selectInfo" style="cursor: pointer;"><Search /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="合同订单号:" prop="customerName">
            <el-input v-model="taskForm.customerName" size="small" class="w200" readonly>
              <template #append >
                <el-icon @click="selectNum" style="cursor: pointer;"><Search /></el-icon>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="业务类型:" prop="customerName">
            <cascader-common
              v-model:modelValue="taskForm.businessTypeId"
              placeholder="请选择业务类型"
              :options="contractTypeOptions"
              :casProps="casProps"
              style="width: 200px"
            />
          </el-form-item>
          <el-form-item label="备注:" prop="customerName">
            <el-input v-model="taskForm.customerName" size="small" class="w500" readonly>
            </el-input>
          </el-form-item>
          <div class="tskTable">
            <div class="taskList" v-for="(item,index) in taskForm.list">
              <div class="circle">
                {{ index + 1 }}
              </div>
              <div>
                <el-input v-model="item.customerName" size="small" class="w100" />
              </div>
              <div>
                <el-input
                  v-model="item.contractAmount"
                  size="small"
                  style="width: 180px"
                  placeholder="请选择资料"
                  readonly
                >
                  <template #append>
                    <el-icon style="cursor: pointer;" @click="selectInfo"><Search /></el-icon>
                  </template>
                </el-input>
              </div>
              <div>
                <div>
                  <el-input v-model="item.customerName" size="small" class="w100" />
                </div>
              </div>
              <div>
                <selectuser code="addCustomer" v-model:userId="item.followUserId" :multiples="false" style="width: 130px;margin: 0;" ></selectuser>
              </div>
              <div>
                <el-icon style="margin-right: 8px;cursor: pointer;" @click="addTask(taskForm.list)"><Plus /></el-icon>
                <el-icon style="cursor: pointer;" @click="delTask(taskForm.list,index)"><Minus /></el-icon>
              </div>
            </div>
          </div>
          <el-form-item label="业务类型:" prop="customerName">
            <el-date-picker
              v-model="taskForm.value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="small"
              style="width: 250px;margin: 0;"
            />
          </el-form-item>
          <el-form-item label="设置办理人:" prop="customerName">
            <selectuser code="addCustomer" v-model:userId="taskForm.followUserId" :multiples="false" style="width: 200px;margin: 0;" ></selectuser>
          </el-form-item>
          <el-form-item label="负责人:" prop="customerName">
            <selectuser code="addCustomer" v-model:userId="taskForm.followUserId" :multiples="false" style="width: 250px;margin: 0;" ></selectuser>
          </el-form-item>
        </el-form>
      </template>
    </el-drawer>
    <el-dialog
      v-model="openNum"
      title="选择合同（订单）号"
      width="790"
      :close-on-click-modal="false"
      align-center
    >
      <div>
        <el-table
          :data="clueAllList"
          style="width: 100%; margin-top: 10px"
          border
          :height="contentStyleObj"
          @selection-change="handleSelectClue"
          v-loading="listLoading"
          stripe
        >
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column type="selection" width="55" align="center" fixed />
          <el-table-column
            prop="customerName"
            label="客户编码"
            min-width="110"
            align="left"
          />
          <el-table-column
            prop="customerName"
            label="客户名称"
            min-width="180"
            align="left"
          />
          <el-table-column
            prop="customerName"
            label="合同（订单）号"
            min-width="110"
            align="left"
          />
          <el-table-column
            prop="customerName"
            label="业务类型"
            min-width="200"
            align="left"
          />
          <el-table-column
            prop="customerName"
            label="金额"
            align="left"
          />
        </el-table>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="openNum = false" size="small">取消</el-button>
          <el-button type="primary"  size="small" @click="save('task')">
            确定
          </el-button>
        </div>
      </template>
    </el-dialog>
    <selectinformation ref="selectinformation" @success="getInfo"></selectinformation>
  </div>
</template>

<script>
import selectinformation from "../../clue/components/selectInformation.vue"

export default {
  name: '',
  components: {
    selectinformation
  },
  props: {
    title:{
      type: String,
      default: '新建任务'
    }
  },
  data () {
    return {
      addDrawer:false,
      taskForm:{
        list:[
          {
            customerName:'',
            contractAmount:'',
            followUserId:''
          }
        ]
      },
      openNum:false,
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {
    init(){
      this.addDrawer = true;
    },
    // 选择资料名称
    selectInfo(){
      this.$refs.selectinformation.init('task')
    },
    selectNum(){
      this.openNum = true
    },
    addTask(item){
      item.push({
        customerName:'',
      });
    },
    delTask(item,index){
      item.splice(index, 1);
    }
  }
}
</script>

<style scoped lang="less">
.drawerHead{
  display: flex;
  justify-content: space-between;
}
.w200{
  width: 250px;
  margin: 0;
}
.w100{
  width: 100px;
  margin: 0;
}
.w500{
  width: 585px;
  margin: 0;
}
.tskTable{
  width: 95%;
  margin: 0 auto;
  height: 280px;
  background-color: #F3F4F5;
  border-radius: 10px;
  overflow-y: auto;
  padding: 10px;
  margin-bottom: 10px;
}
.circle{
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #CDCDCD;
  border-radius: 50%;
}
.taskList{
  display: flex;
  height: 40px;
  line-height: 40px;
  justify-content: space-around;
  align-items: center;
}
</style>
